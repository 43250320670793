import CareerCards from "./card";
import "./index.scss";

function CareerOpportunity() {
  return (
    <div className="container-fluid career-block">
      <div className="row about-us-first-row">
        <div className="about-us-header-text">CAREER OPPORTUNITY</div>
      </div>
      <div className="row career-block-row">
        <h1 className="">LOCAL RECRUITMENT</h1>

        <h3 className="">
          Work Wizard helps candidates achieve their dream company placements by
          providing valuable assistance and support.
        </h3>
      </div>
      <div className="row career-cards-section">
        <CareerCards />
      </div>
    </div>
  );
}
export default CareerOpportunity;
