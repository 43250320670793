import "./index.scss";
import w from "../../assets/images/About/w.png";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

function AboutUs() {
  return (
    <div className="container-fluid about-us-page">
      <div className="row about-us-first-row">
        <div className="about-us-header-text">ABOUT US</div>
      </div>
      <div className="row about-us-second-row">
        <div className="col-lg-4 about-us-second-row-col">
          <img src={w} alt="" className="img-fluid w-image" />
        </div>
        <div className="col-lg-8">
          <div className="row about-heading">
            <h1 className="">
              Work Wizard's strategy for a better and more sustainable future?
            </h1>
            <h2 className="">Empowering Change, Enriching Tomorrow</h2>
            <h3 className="">
              Our Vision is to harness innovation, sustainability, and
              compassion to create a brighter future for all. Through
              purpose-driven initiatives, we strive to positively impact
              communities, the environment, and lives worldwide, fostering a
              world that thrives on harmony, equality, and progress."
            </h3>
            <div className="explore-button-section">
              <Link className=" explore-button" to={"our-services"}>
                <span>EXPLORE CAREERS</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AboutUs;
