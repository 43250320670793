import "./index.scss";
function FowerWarehouseJob() {
  const data = [
    {
      title: "Position Details:",
      items: [
        "Location: Stężyca (60 km from Radom)",
        "Age: Up to 55 years",
        "Start Date: Immediate",
        "Contact: Olga +48 787 567 270",
      ],
    },
    {
      title: "Work Details:",
      items: [
        "Pay: 20 PLN net per hour for women, 23.50 PLN net per hour for men, 26.50 PLN net per hour for UDT certified workers",
        "Work 6 days a week (Monday to Saturday)",
        "Shifts: 10-12 hours per day",
      ],
    },

    {
      title: "ACCOMMODATION:",
      items: [
        " Provided at 800 PLN/month along with your salary",
        " Comfortable house with 8 people, 2-3 people per room",
        "Located 3 km from the workplace",
        "Free bicycles provided for commuting",
      ],
    },

    {
      title: "RESPONSIBILITIES:",
      items: [
        "Trimming roses",
        "Regular plant watering",
        "Precise application of fertilisers and nutrients",
        "Greenhouse monitoring",
        "Picking flowers based on maturity levels",
        "Ensure safety protocols are followed",
      ],
    },

    {
      title: "ADDITIONAL DETAILS:",
      items: [
        "IMMEDIATE START",
        "LEGAL EMPLOYMENT UNDER A CONTRACT WORK AGREEMENT",
        "OPPORTUNITY TO APPLY FOR A RESIDENCY CARD",
      ],
    },

    {
      title: "CONTACT US AT",
      items: [
        "+48 787 567 270",
        "via phone, Telegram, or Viber for more information.",
      ],
    },

    // Add more objects as needed
  ];
  return (
    <div className="container-fluid local-recruitment-component">
      <div className="row lrc-header-row">
        <h1>LOCAL RECRUITMENT</h1>
        <h2>Flower Warehouse Workers!</h2>
        <h3>Looking for a blooming opportunity?</h3>
        <h4>
          Join our team at JMP FLOWERS in Stężyca (just 60 km from Radom). We're
          seeking both women and men up to 55 years old for an easy and
          rewarding job!
        </h4>
      </div>
      {data.map((section, index) => (
        <div key={index} className="row lrc-details-row">
          <h1>{section.title}</h1>
          <ul>
            {section.items.map((item, itemIndex) => (
              <p key={itemIndex}>- {item}</p>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}
export default FowerWarehouseJob;
