import "./index.scss";
import s from "../../assets/images/letters/S.png";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Card } from "@mui/material";

function OurServices() {
  return (
    <div className="container-fluid our-services-page">
      <div className="row our-services-first-row">
        <div className="our-services-header-text">OUR SERVICES</div>
      </div>
      <div className="row our-services-second-row">
        <div className="col-lg-3 our-services-second-row-col">
          <img src={s} alt="" className="img-fluid w-image" />
        </div>
        <div className="col-lg-9">
          <div className="row our-services-heading">
            <h1 className="">
              Experienced and Unparalleled Career Assistance with Work Wizard
            </h1>
          </div>
          <div className="row">
            <div className="col-lg-4 card-section">
              <h2 className="">Local Recruitment</h2>
              <h3 className="">
                At Work Wizard, we take pride in connecting talented individuals
                residing in the Schengen Area with exciting job opportunities
                within their local communities...
              </h3>
              <Link
                className="read-more-section read-more"
                to={"/career-opportunity"}
              >
                <span>READ MORE</span>
              </Link>
            </div>
            <div className="col-lg-4 card-section">
              <h2 className="">Move To Poland</h2>
              <h3 className="">
                Elevate Your Career. High-Paying Opportunities in Poland for GCC
                & Non-Schengen Candidates. Stress-Free Relocation:Seamlessly
                Move with Expert Handling...
              </h3>
              <Link
                className="read-more-section read-more"
                to={"/move-to-poland"}
              >
                <span>READ MORE</span>
              </Link>
            </div>
            <div className="col-lg-4 card-section">
              <h2 className="">Training Program</h2>
              <h3 className="">
                We are excited to introduce our company's latest offering - the
                Code 95 online training program in Poland. Our program is
                designed specifically for...
              </h3>
              <Link
                className="read-more-section read-more"
                to={"/training-program"}
              >
                <span>READ MORE</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default OurServices;
