import React from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  Navigate,
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Home from "./layout/components/Welcome-Screen";

import Header from "./layout/components/Header";

import LocalRecruitmentPage from "./layout/components/Flower-Warehouse/warehouse-job";

import ServicePage from "./layout/components/Our-Services/our_service";
import AboutPage from "./layout/components/About-Us/about_us";
import MoveToPolandPage from "./layout/components/Move-to-Poland/move_to_poland";
import ContactPage from "./layout/components/Contact-Us/contact";
import CareeerPage from "./layout/components/Career-Oppourtunty/career_opportunity";
import TrainingPage from "./layout/components/Training/training";
import WarehouseJobPage from "./layout/components/Flower-Warehouse/warehouse-job";
import RecruitmentPage from "./layout/components/Local-Recruitment/local_recruitment";
import ChocolateFactoryJobPage from "./layout/components/Chocolate-Factory/chocolate-factory-job";
import MasterBPRJobPage from "./layout/components/Master-of-BPR/BPR-Job";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/home" element={<Navigate to="/" />} />
          <Route path="/" element={<Home />} />
          <Route path="/header" element={<Header />} />

          <Route path="/about-us" element={<AboutPage />} />
          <Route path="/about-us/our-services" element={<ServicePage />} />
          <Route path="/our-services" element={<ServicePage />} />
          <Route path="/local-recruitment" element={<RecruitmentPage />} />
          <Route path="/training-program" element={<TrainingPage />} />
          <Route path="/move-to-poland" element={<MoveToPolandPage />} />
          <Route path="/career-opportunity" element={<CareeerPage />} />

          <Route
            path="/flower-warehouse-workers"
            element={<WarehouseJobPage />}
          />
          <Route
            path="/chocolate-factory-operator"
            element={<ChocolateFactoryJobPage />}
          />
          <Route
            path="/masters-of-brake-pad-refinement"
            element={<MasterBPRJobPage />}
          />

          <Route path="/contact-us" element={<ContactPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
