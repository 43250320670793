import MasterBPR from ".";
import FowerWarehouseJob from ".";
import LocalRecruitment from ".";
import Footer from "../Footer";
import Header from "../Header";
import "./index.scss";
function MasterBPRJobPage() {
  return (
    <>
      <Header />
      <MasterBPR />
      <Footer />
    </>
  );
}
export default MasterBPRJobPage;
