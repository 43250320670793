import "./card.scss";
import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

function CareerCards() {
  return (
    <div className="container-fluid career-card-block">
      <div className="row">
        <div className="col-lg-4 card-col">
          <Card
          // sx={{ maxWidth: 345 }}
          >
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                className="card-header"
              >
                Flower Warehouse Worker!
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Looking for a blooming opportunity? Join our team at JMP FLOWERS
                in Stężyca (just 60 km from Radom). We're seeking both women and
                men up to 55 years old for an easy and rewarding job!
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" className="btn-btn">
                Refinement
              </Button>
              <Button size="small" className="btn-btn">
                Local Jobs
              </Button>
              <Button size="small" className="btn-btn">
                Recruitment
              </Button>
            </CardActions>
            <CardActions>
              <Button
                variant="contained"
                href="/flower-warehouse-workers"
                className="apply-button"
              >
                APPLY NOW
              </Button>
            </CardActions>
          </Card>
        </div>
        <div className="col-lg-4 card-col">
          <Card
          //  sx={{ maxWidth: 345 }}
          >
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                className="card-header"
              >
                Chocolate Factory Operator
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Are you looking for a sweet job opportunity? Join us at FABRYKA
                CZEKOLADY as a Line Operator in Elbląg (just 80 km from Gdańsk).
                We're hiring men up to 55 years old with no prior experience or
                language requirements...
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" className="btn-btn">
                Refinement
              </Button>
              <Button size="small" className="btn-btn">
                Local Jobs
              </Button>
              <Button size="small" className="btn-btn">
                Recruitment
              </Button>
            </CardActions>
            <CardActions>
              <Button
                variant="contained"
                href="/chocolate-factory-operator"
                className="apply-button"
              >
                APPLY NOW
              </Button>
            </CardActions>
          </Card>
        </div>
        <div className="col-lg-4 card-col">
          <Card
          //  sx={{ maxWidth: 345 }}
          >
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                className="card-header"
              >
                Masters of Brake Pad Refinement!
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Looking for a blooming opportunity? Join our team at JMP FLOWERS
                in Stężyca (just 60 km from Radom). We're seeking both women and
                men up to 55 years old for an easy and rewarding job!
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" className="btn-btn">
                Refinement
              </Button>
              <Button size="small" className="btn-btn">
                Local Jobs
              </Button>
              <Button size="small" className="btn-btn">
                Recruitment
              </Button>
            </CardActions>
            <CardActions>
              <Button
                variant="contained"
                href="/masters-of-brake-pad-refinement"
                className="apply-button"
              >
                APPLY NOW
              </Button>
            </CardActions>
          </Card>
        </div>
        {/* <div className="col-lg-4">
          <Card sx={{ maxWidth: 345 }}>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                className="card-header"
              >
                Lizard
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Lizards are a widespread group of squamate reptiles, with over
                6,000 species, ranging across all continents except Antarctica
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small">Share</Button>
              <Button size="small">Learn More</Button>
              <Button size="small">Learn More</Button>
            </CardActions>
            <CardActions>
              <Button variant="contained">Custom CSS</Button>
            </CardActions>
          </Card>
        </div> */}
      </div>
    </div>
  );
}
export default CareerCards;
