import "./index.scss";
function MasterBPR() {
  const data = [
    {
      title: "Work Details:",
      items: [
        "🌟 We offer 3 SHIFTS to suit your lifestyle:",
        "✅ 6 AM - 2 PM",
        "✅ 2 PM - 10 PM",
        "Contact: Olga +48 787 567 270",
        "✅ 10 PM - 6 AM",
        "Work for just 8 hours a day, 5 - 6 days a week.",
        "Get ready for 220-240 working hours every month!",
        "💰 Earn a competitive wage:",
        "- 20.50 PLN per hour (net) + bonuses",
        "- Students - 23.50 PLN per hour (net) + bonuses",
      ],
    },

    {
      title: "💰 Earn a competitive wage:",
      items: [
        "20.50 PLN per hour (net) + bonuses",
        "Students - 23.50 PLN per hour (net) + bonuses",
        "Achieve beyond the norm? Earn an extra 0.80 gr/hour!",
      ],
    },

    {
      title: "LOCATION:",
      items: ["📍 Location: Ostrava (just 90 km from Katowice)"],
    },

    {
      title: "TRANSPORTATION:",
      items: [" 🚗 Free transportation from Jastrzębie Zdrój"],
    },
    {
      title: "ACCOMODATION: ",
      items: [
        " 🏠 Need a place to stay? We've got you covered!",
        "🏡 Accommodation provided at just 400 PLN/month.",
      ],
    },

    {
      title: "RESPONSIBILITIES:",
      items: [
        "✅ Prepare equipment: Configure and inspect tools of the trade.",
        "✅ Follow all safety rules and workplace norms.",
        "✅ Grinding: Perform precision grinding operations to remove wear from brake pad surfaces.",
        "✅ Polishing: Utilise special polishing materials and techniques to achieve smooth, shiny brake pad surfaces.",
        "✅ Monitor quality standards and visually inspect finished parts for any defects.",
      ],
    },

    {
      title: "ADDITIONAL DETAILS:",
      items: [
        "🛡️ *Important Notice: For your security and ours, we require a 1500 PLN deposit to avoid fraudulent applications. This deposit will be returned to you along with your salary in the fourth month of your employment. Please apply only if you are willing to comply with this condition.*",
        "IMMEDIATE START",
        "LEGAL EMPLOYMENT UNDER A CONTRACT WORK AGREEMENT",
        "OPPORTUNITY TO APPLY FOR A RESIDENCY CARD",
      ],
    },

    {
      title: "CONTACT US AT",
      items: [
        "+48 787 567 270",
        "via phone, Telegram, or Viber for more information.",
      ],
    },

    // Add more objects as needed
  ];
  return (
    <div className="container-fluid local-recruitment-component">
      <div className="row lrc-header-row">
        <h1>LOCAL RECRUITMENT</h1>
        <h2>Masters of Brake Pad Refinement!</h2>
        <h3>Looking for Exciting Opportunities? Join Us Now! </h3>
        <h4>
          Are you ready to embark on an incredible journey in the world of
          automotive excellence? We're on the hunt for skilled individuals to
          join our team and become masters of Brake Pad Refinement!
        </h4>
      </div>
      {data.map((section, index) => (
        <div key={index} className="row lrc-details-row">
          <h1>{section.title}</h1>
          <ul>
            {section.items.map((item, itemIndex) => (
              <p key={itemIndex}>- {item}</p>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}
export default MasterBPR;
