import ChocolateFactoryJob from ".";
import Footer from "../Footer";
import Header from "../Header";
import "./index.scss";
function ChocolateFactoryJobPage() {
  return (
    <>
      <Header />
      <ChocolateFactoryJob />
      <Footer />
    </>
  );
}
export default ChocolateFactoryJobPage;
