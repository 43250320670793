import "./index.scss";
function ChocolateFactoryJob() {
  const data = [
    {
      title: "Position Details:",
      items: [
        "Location: Elbląg (80 km from Gdańsk)",
        "Age: Up to 55 years",
        "Start Date: Immediate",
        "Contact: Olga +48 787 567 270",
      ],
    },
    {
      title: "Work Details:",
      items: [
        "3 Shifts Available",
        "Pay: 19.00 PLN net per hour + bonuses ranging from 100 to 1000 PLN",
        "Students: 23.50 PLN net per hour + bonuses ranging from 100 to 1000 PLN",
        "Contact: Olga +48 787 567 270",
        "5-6 days a week",
        "Guaranteed 220 hours of work per month",
      ],
    },

    {
      title: "ACCOMMODATION:",
      items: [" PROVIDED AT 600 PLN/MONTH", " SAFETY CLOTHING PROVIDED"],
    },

    {
      title: "RESPONSIBILITIES:",
      items: [
        "MAINTAIN PRODUCTION LINE",
        "ENSURE PRODUCT QUALITY MEETS STANDARDS",
        "Students: 23.50 PLN net per hour + bonuses ranging from 100 to 1000 PLN",
        "MEASURE PARAMETERS",
        "QUALITY CONTROL",
      ],
    },

    {
      title: "ADDITIONAL DETAILS:",
      items: [
        "150 PLN IS DEDUCTED FROM THE SALARY FOR SANITARY-EPIDEMIOLOGICAL CHECKS (IF AN EMPLOYEE DOES NOT HAVE A SANITARY-EPIDEMIOLOGICAL BOOK)",

        "IMMEDIATE START",
        "LEGAL EMPLOYMENT UNDER A CONTRACT WORK AGREEMENT",
        "OPPORTUNITY TO APPLY FOR A RESIDENCY CARD",
      ],
    },

    {
      title: "CONTACT US AT",
      items: [
        "+48 787 567 270",
        "via phone, Telegram, or Viber for more information.",
      ],
    },

    // Add more objects as needed
  ];
  return (
    <div className="container-fluid local-recruitment-component">
      <div className="row lrc-header-row">
        <h1>LOCAL RECRUITMENT</h1>
        <h2>Chocolate Factory Operator!</h2>
        <h3>Are you looking for a sweet job opportunity?</h3>
        <h4>
          Join us at FABRYKA CZEKOLADY as a Line Operator in Elbląg (just 80 km
          from Gdańsk). We're hiring men up to 55 years old with no prior
          experience or language requirements - we'll teach you everything you
          need to know!
        </h4>
      </div>
      {data.map((section, index) => (
        <div key={index} className="row lrc-details-row">
          <h1>{section.title}</h1>
          <ul>
            {section.items.map((item, itemIndex) => (
              <p key={itemIndex}>- {item}</p>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}
export default ChocolateFactoryJob;
