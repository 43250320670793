import React, { useEffect } from "react";
import OurServices from ".";

import Footer from "../Footer";
import Header from "../Header";

function ServicePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <OurServices />
      <Footer />
    </>
  );
}

export default ServicePage;
