import "./index.scss";
import homebanner from "../../assets/images/Home/home-img.png";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

function WelcomeScreen() {
  return (
    <div className="container-fluid welcome-screen-container">
      <div className="row welcome-screen-row">
        <h1 className="welcome-screen-text">
          Unlock extraordinary <span>job opportunities</span> and opportunities
          and exceptional talent all in one place.
        </h1>
      </div>
      <div className="row first-section-start">
        <Link to={"about-us"} className="first-section-start-btn">
          <div>
            <span>GET STARTED</span>
          </div>
        </Link>
      </div>
      <div className="row welcome-screen-row">
        <img src={homebanner} alt="" className="img-fluid welcome-screen-img" />
      </div>
    </div>
  );
}
export default WelcomeScreen;
