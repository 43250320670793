import "./index.scss";
import React from "react";
import t from "../../assets/images/letters/T.png";
const visaData = [
  {
    heading: "Qualifications Needed for Candidates:",
    title:
      "To be eligible for the Code 95 online training program, candidates must meet the following qualifications:",

    items: [
      {
        description: "1. Possess a valid driver's license.",
      },
      {
        description:
          "2. Have a minimum of three years of experience in the field Speak English.",
      },
    ],
  },

  {
    heading:
      "Procedure for the Code 95 Online Training Program and Job Placement:",
    title:
      "Here is a brief overview of the Code 95 online training program and securing your Job procedure:",
    items: [
      {
        description:
          "1. Implementation of all necessary documents to obtain a professional driver's profile (PKZ), which includes psychological and medical evaluations.",
      },
      {
        description:
          "2 Conducting the accelerated initial qualification course.",
      },
      {
        description:
          "3. Registering candidates for one state exam with a sworn translator to obtain a driver qualification card.",
      },
      {
        description:
          "4. Applying for work permits for selected candidates. It takes approximately 60 to 75 working days for the permit to be issued. During this time, candidates will also complete the online training program, and we will provide them with a course completion certificate.",
      },
      {
        description:
          "5. Candidates will need to submit all necessary documents at the embassy to obtain their visas. Once the visa is issued, candidates can come to Poland and complete the exams. All necessary cards and documents will be issued within 30 to 45 days.",
      },
      {
        description:
          "6.We will provide accommodation during this period, but candidates will need to cover their own food and travel expenses.",
      },
      {
        description:
          "7. Once candidates receive all the valid cards and documents, they can begin their jobs at their respective companies. The minimum salary is 1500 Euros, which can range up to 3000 Euros depending on the driver's experience and the type of cargo they handle.",
      },

      {
        description:
          "Our program provides a range of services to help drivers obtain all the necessary documents for the job, and we guarantee both the job and salary. We believe that our Code 95 online training program offers a unique opportunity for non-European drivers to establish their careers as heavy-duty drivers in Poland.",
      },
    ],
  },

  {
    heading: "Trailer Drivers (with Code 95 Online Training)",
    title: "SALARY:",
    titles: "Trailer Drivers:",
    items: [
      {
        description:
          "In the first month, there is no salary. However, after qualifying the exam, drivers will receive an initial payment of 4000 zl for the following two months. After that, the salary ranges from 1500 to 2000 Euro.",
      },
    ],
  },
  {
    titles: "Application Process Time:",
    items: [
      {
        description:
          "Trailer Drivers (including training): The application process takes around 75 working days.",
      },
    ],
  },
];

function Training() {
  return (
    <div className="container-fluid information-container">
      <div className="row our-services-first-row">
        <div className="our-services-header-text">TRAINING</div>
      </div>
      <div className="row our-services-second-row">
        <div className="col-lg-3 our-services-second-row-col">
          <img src={t} alt="" className="img-fluid w-image" />
        </div>
        <div className="col-lg-9">
          <div className="row about-heading">
            <h1 className="">TRAINING PROGRAM</h1>
            <h2 className="">GREETINGS!!</h2>
            <h3 className="">
              We are excited to introduce our company's latest offering - the
              Code 95 online training program in Poland. Our program is designed
              specifically for non-European drivers who wish to establish their
              careers as heavy-duty drivers in Poland. It offers a convenient
              and efficient way to complete the necessary training before
              arriving in Poland, thus enabling drivers to get to work faster.
            </h3>
          </div>
        </div>
      </div>

      {visaData.map((section, sectionIndex) => (
        <div key={sectionIndex} className="row informations-row">
          <h2>{section.heading}</h2>
          <p>{section.title}</p>
          {section.items.map((item, itemIndex) => (
            <div key={itemIndex}>
              <p>{section.titles}</p>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default Training;
