import "./index.scss";
import c from "../../assets/images/letters/C.png";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Card } from "@mui/material";

function ContactUs() {
  return (
    <div className="container-fluid our-services-page">
      <div className="row our-services-first-row">
        <div className="our-services-header-text">CONTACT US</div>
      </div>
      <div className="row our-services-second-row">
        <div className="col-lg-3 our-services-second-row-col">
          <img src={c} alt="" className="img-fluid w-image" />
        </div>
        <div className="col-lg-9 contact-details">
          <h1>Work Wizard is here to help you</h1>
          <p>
            Our experts are available to answer any questions you might have.
            We’ve got the answers.
          </p>
          <h2>
            <span>GET IN </span>TOUCH
          </h2>
          <p>+48 45 242 14 55, +48 452 448 520</p>
          <h2>
            <span>Visit </span> Us
          </h2>
          <p>
            ul. BIEŻUŃSKA 1, lok. <br />
            6, 03-578, WARSZAWA, POLSKA
          </p>
          <h2>
            <span>Email </span> US
          </h2>
          <p>info@workwizard.pl</p>
        </div>
      </div>
    </div>
  );
}
export default ContactUs;
