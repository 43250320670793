import "./index.scss";
import React from "react";
import m from "../../assets/images/letters/M.png";

const visaData = [
  {
    heading: "General Category Jobs:",
    items: [
      {
        title: "1. Construction Worker : ",
        description:
          "Help with various construction tasks such as carrying materials, cleaning, and assisting skilled workers on construction sites.",
      },

      {
        title: "2. Warehouse Worker : ",
        description:
          "Responsibilities may include packaging, sorting, and loading/unloading goods in warehouses or distribution centers.",
      },
      {
        title: "2. Farm Worker : ",
        description:
          "Responsibilities may include packaging, sorting, and loading/unloading goods in warehouses or distribution centers.",
      },
      {
        title: "4. Cleaner Positions: ",
        description:
          "Explore opportunities in the field of cleaning and maintenance, encompassing tasks related to offices, homes, hotels, and public spaces. Join us in ensuring cleanliness and order in various environments through fulfilling roles in the cleaning industry.",
      },
      {
        title: "5. Production Line Worker Roles: ",
        description:
          "Engage in the dynamic field of production line work, contributing to the assembly of products in factories or manufacturing plants. This role involves working on assembly lines, ensuring the efficient and accurate production of various goods.",
      },
      {
        title: "6. Hospitality Positions: ",
        description:
          "Explore opportunities in the vibrant hospitality industry with roles such as kitchen assistants, dishwashers, and hotel housekeeping staff. Join us in providing excellent service and maintaining the high standards of restaurants and hotels.",
      },
      {
        title: "7. Landscaping/Gardening Roles: ",
        description:
          "Embark on a fulfilling career in landscaping and gardening, contributing to the maintenance and beautification of parks, gardens, and outdoor spaces. Join our team to create and preserve green environments for the community to enjoy.",
      },
    ],
  },

  {
    heading: "Documents Provided from Work Wizard",
    items: [
      {
        title: "Documents Required : ",
        description: "1. Passport (ALL PAGES in Single PDF)",
      },
    ],
  },
  {
    heading: "Documents Provided from Work Wizard",
    items: [
      {
        title: "1. Work Permit : ",
        description:
          "A work permit is an official document issued by the government of a country that allows a foreign national to legally work in that country for a specific employer and for a limited period. It is a critical component of the visa process for those seeking employment abroad. The work permit outlines the terms and conditions of the job, such as the position, duration of employment, and the employer's information. It is essential to have a valid work permit to work legally in most countries as a foreign national.",
      },
      {
        title: "2. Contract Letter : ",
        description:
          "A contract letter, also known as an employment contract or job offer letter, is a formal document provided by the employer to the employee, detailing the terms and conditions of employment. It includes essential information such as the job title, job responsibilities, salary, working hours, benefits, and other employment-related terms. The contract letter is a crucial part of the visa process, as it demonstrates the job offer and helps immigration authorities assess the nature of the employment and its compliance with labor laws.",
      },
      {
        title: "3. Accommodation Letter : ",
        description:
          "An accommodation letter is a document that verifies the availability of suitable accommodation for a visa applicant in the destination country. It is often required by visa authorities to ensure that the applicant has a place to stay during their visit or employment period. The accommodation letter may be provided by an employer, educational institution, or a host, depending on the purpose of the visit.",
      },
      {
        title: "4. Guarantee Letter : ",
        description:
          "A guarantee letter is a written statement from a sponsoring individual or organisation that takes responsibility for the visa applicant during their stay in the country. The guarantor typically assures that the applicant will not overstay their visa, engage in illegal activities, and will have sufficient financial support if needed. The guarantee letter is often required for various types of visas, especially when the applicant's financial situation or purpose of the visit raises concerns for the visa authorities.",
      },
    ],
  },
  {
    heading: "PROCESS",
    items: [
      {
        title: "1. Work Permit Application : ",
        description:
          "The company you're planning to work for will apply for a work permit on your behalf. The work permit is an official document that allows you to work legally in the country where the company operates. Typically, it takes about 60 working days for the company to obtain the work permit. However, to account for any potential delays or errors in the process, they have allocated a buffer period of up to 80 days.",
      },
      {
        title: "2. Document Preparation : ",
        description:
          "Once the work permits are issued and approved, the company will proceed with preparing the remaining documents necessary for your employment. This process usually takes an additional 3 to 4 days.",
      },
      {
        title: "3. Visa Application : ",
        description:
          "Alongside the company's work permit application, you, as the candidate, need to apply for a visa to enter the country legally. The visa will allow you to stay and work in the country for the duration specified in the work permit. The specific visa application process and requirements may vary depending on the country and your nationality.",
      },
    ],
  },

  {
    heading: "General details :",
    items: [
      {
        title: " 1. Salary Range : ",
        description:
          "The salary offered for these positions will be between 17 to 19 Polish złoty (zł) per hour. The exact pay may vary depending on the specific job and the employer.",
      },
      {
        title: "2. Job Availability : ",
        description:
          "Once the work permits are issued and approved, the company will proceed with preparing the remaining documents necessary for your employment. This process usually takes an additional 3 to 4 days.",
      },
      {
        title: "3. Job Categories : ",
        description:
          "The availability of vacancies for the mentioned job categories may vary from time to time. As these jobs are typically for unskilled labourer's, they may depend on factors such as seasonal demand, economic conditions, and employer requirements.",
      },
    ],
  },

  {
    heading: "terms:",
    items: [
      {
        title: "1. Visa Application and VFS Responsibility : ",
        description:
          "The agency is responsible for handling the entire visa application process and managing the Visa Application Center (VFS) procedures. This means that the agency will handle all the necessary paperwork, submissions, and communication with the relevant visa authorities to facilitate the visa application on behalf of the candidates.",
      },
      {
        title: "2. Temporary Residence Card Support : ",
        description:
          "The company will extend all necessary support to the candidates to obtain a temporary residence card. This card allows candidates to legally reside and work in Poland for a specific period. However, there is a condition attached: The agency must ensure that the candidate commits to working for the company for a minimum span of 8 months. This condition may be stated in the contract or agreement between the agency and the candidate.",
      },
      {
        title: "3. Non-Guaranteed Visa : ",
        description:
          "It's important to note that the visa application process does not guarantee a visa approval. Even if the company provides all legitimate documents and support, the final decision to issue the visa solely rests with the visa officer. This means that the candidates must understand and accept the possibility that their visa application could be denied, even with the company's assistance.",
      },
      {
        title: "4. Processing in Batches: ",
        description:
          "The company's recruitment and visa application process are organized in batches. Each batch ideally consists of a minimum of 10 to 15 employees. This batch processing occurs regularly every month. Consequently, the company systematically hires and processes visa applications for a group of candidates together on a monthly basis.",
      },
    ],
  },
];

function MoveToPoland() {
  return (
    <div className="container-fluid information-container">
      <div className="row our-services-first-row">
        <div className="our-services-header-text">OUR SERVICES</div>
      </div>
      <div className="row our-services-second-row">
        <div className="col-lg-4 our-services-second-row-col">
          <img src={m} alt="" className="img-fluid w-image" />
        </div>
        <div className="col-lg-8">
          <div className="row about-heading">
            <h1 className="">MOVE TO POLAND</h1>
            <h2 className="">Welcome to Your New Journey in Poland!</h2>
            <h3 className="">
              We are thrilled to announce our latest initiative – the 'Move to
              Poland' program. Tailored for individuals aspiring to build a
              successful career and life in Poland, this program provides
              comprehensive support and resources to make your relocation
              seamless and rewarding. Whether you are moving for work, study, or
              a fresh start, we've got you covered every step of the way.
            </h3>
          </div>
        </div>
      </div>
      {visaData.map((section, sectionIndex) => (
        <div key={sectionIndex} className="row informations-row">
          <h2>{section.heading}</h2>
          {section.items.map((item, itemIndex) => (
            <div key={itemIndex}>
              <p>
                {" "}
                <span>{item.title}</span>
                {""}
                {item.description}
              </p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default MoveToPoland;
