import "./index.scss";
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import HeaderIconMob from "../../assets/images/Header/WORK WIZARD LOGO 2.svg";
import HeaderIcon from "../../assets/images/Header/WORK WIZARD LOGO 1.svg";
import MenuIcon from "../../assets/images/Header/MenuIcon.svg";
import { Link } from "react-router-dom";
// import CloseIcon from "../../assets/images/Header/MenuIcon.svg";

const menuItems = [
  {
    column: "Quick Links",
    items: [
      "Home",
      "About Us",
      "Our Services",
      "Career Opportunity",
      "Contact Us",
    ],
  },
  {
    column: "Our Services",
    items: ["Local Recruitment", "Move to Poland", "Training Program"],
  },
  // {
  //   column: "Career Opportunity",
  //   items: ["Local Recruitment", "Move to Poland", "Training Program"],
  // },
  {
    column: "Job Openings",
    items: [
      "Masters of Brake Pad Refinement",
      "Chocolate Factory Operator",
      "Flower Warehouse Workers",
    ],
  },
];

function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const renderMenu = (anchorEl: null | HTMLElement) => {
    return (
      <Menu
        sx={{
          mt: "8%",
          "& .MuiPaper-root": {
            backgroundColor: "black",
            width: "100%",
          },
          "& .MuiTypography-root": {
            color: "white",
          },
        }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseUserMenu}
      >
        <Container sx={{ display: "flex" }}>
          {menuItems.map((column, columnIndex) => (
            <Box key={columnIndex} sx={{ flex: 1, textAlign: "center" }}>
              <MenuItem>
                <Typography
                  variant="h6"
                  gutterBottom
                  className="custom-text-color"
                >
                  {column.column}
                </Typography>
              </MenuItem>
              {column.items.map((item, itemIndex) => (
                <MenuItem key={itemIndex} onClick={handleCloseUserMenu}>
                  <Link
                    to={`/${item.toLowerCase().replace(/\s+/g, "-")}`}
                    className="no-text-decoration"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      textAlign="center"
                      className="custom-list-text-color"
                    >
                      {item}
                    </Typography>
                  </Link>
                </MenuItem>
              ))}
            </Box>
          ))}
        </Container>
      </Menu>
    );
  };

  return (
    <Container maxWidth={false}>
      <Toolbar disableGutters>
        <img src={HeaderIcon} alt="" className="img-fluid logo" />
        <img src={HeaderIconMob} alt="" className="img-fluid logo-mob" />

        <Box
          sx={{
            flexGrow: 0,
            display: { xs: "none", md: "flex" },
          }}
        ></Box>

        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "flex", md: "flex" },
            justifyContent: "flex-end",
          }}
        >
          <Tooltip title="Open Menu">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <img src={MenuIcon} alt="" className="img-fluid menu-bar-icon" />
            </IconButton>
          </Tooltip>

          {renderMenu(anchorElUser)}
        </Box>
      </Toolbar>
    </Container>
  );
}

export default Header;
