import "./index.scss";
import React from "react";
import l from "../../assets/images/letters/L.png";
const visaData = [
  {
    heading: "WHY YOU CHOOSE US?",
    items: [
      {
        title: "1. In-depth Knowledge: ",
        description:
          "We have an extensive understanding of the job market and industry trends within the Schengen Area, allowing us to identify the most suitable opportunities for our candidates.",
      },
      {
        title: "2. Local Expertise: ",
        description:
          "Our team is well-versed in the cultures, languages, and regulations of the Schengen countries. This expertise ensures a seamless recruitment experience tailored to the specific needs of each country.",
      },
      {
        title: "3. Vast Network: ",
        description:
          "With a vast network of employers and businesses across the Schengen Area, we can connect job seekers with a wide range of job openings that match their skills and preferences.",
      },
    ],
  },
  {
    heading: "OUR SERVICES",
    items: [
      {
        title: "Personalised Job Search: ",
        description:
          "We work closely with candidates to understand their career goals, skills, and preferences. This personalised approach allows us to match them with suitable job openings that align with their aspirations.",
      },
      {
        title: "Employer Partnerships: ",
        description:
          "We collaborate with a diverse pool of employers, from small businesses to multinational corporations, helping them find the right talent to meet their staffing needs.",
      },
      {
        title: "Job Application Support: ",
        description:
          "Our team provides guidance and support throughout the application process, from resume building to interview preparation, ensuring candidates present their best selves to potential employers.",
      },
      {
        title: "Legal Compliance: ",
        description:
          "Navigating employment regulations in the Schengen Area can be complex. We assist both candidates and employers in understanding and adhering to all relevant legal requirements.",
      },
      {
        title: "Continuous Support: ",
        description:
          "Our relationship with candidates doesn't end once they secure a job. We offer ongoing support to ensure a smooth integration into their new role.",
      },
      {
        description:
          "Whether you are a skilled or unskilled professional seeking new opportunities or an employer searching for top local talent, Work Wizard is your trusted partner in the Schengen recruitment landscape. Join us today and let's unlock your career potential or enhance your workforce together!",
      },
    ],
  },
];

function LocalRecruitment() {
  return (
    <div className="container-fluid information-container">
      <div className="row our-services-first-row">
        <div className="our-services-header-text">OUR SERVICES</div>
      </div>
      <div className="row our-services-second-row">
        <div className="col-lg-3 our-services-second-row-col">
          <img src={l} alt="" className="img-fluid w-image" />
        </div>
        <div className="col-lg-9">
          <div className="row about-heading">
            <h1 className="">LOCAL RECRUITMENT</h1>
            <h2 className="">
              Welcome to our Local Recruitment Services for Schengen Residents!
            </h2>
            <h3 className="">
              At Work Wizard, we take pride in connecting talented individuals
              residing in the Schengen Area with exciting job opportunities
              within their local communities. Our dedicated team of experts
              understands the unique needs and challenges faced by Schengen
              residents, and we are here to streamline the recruitment process
              for both job seekers and employers.
            </h3>
          </div>
        </div>
      </div>

      {visaData.map((section, sectionIndex) => (
        <div key={sectionIndex} className="row informations-row">
          <h2>{section.heading}</h2>
          {section.items.map((item, itemIndex) => (
            <div key={itemIndex}>
              <p>
                {" "}
                <span>{item.title}</span>
                {""}
                {item.description}
              </p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default LocalRecruitment;
