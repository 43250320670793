import React from "react";
import LinkedInIcon from "../../assets/images/footer/linkedin.svg";
import FacebookIcon from "../../assets/images/footer/facebook.svg";
import InstagramIcon from "../../assets/images/footer/instagram.svg";
import TwitterIcon from "../../assets/images/footer/twitter.svg";
import "bootstrap/dist/css/bootstrap.css";

import Button from "@mui/material/Button";

import "./index.scss";

function Footer() {
  return (
    <div className="container-fluid footer-section-container">
      <div className="col-xs-12 footer-section-main">
        <div className="row   footer-section-content">
          <div className="col-lg-4 work-wizard-container">
            <h1>Work Wizard</h1>
            <p>
              It is a platform providing career guidance, job search support,
              resume building, interview preparation, and skill development
              services.
            </p>
          </div>
          <div className="col-lg-2 pages-container">
            <a href="/about-us" className="link-text">
              <h5>About Us</h5>
            </a>
            <h5>Our Services</h5>
            <h5>Career opportunity</h5>
            <h5>Contact Us</h5>
          </div>
          <div className="col-lg-2 info-container">
            <h5>Local Recruitment</h5>
            <h5>Move to Poland</h5>
            <h5>Training Program</h5>
          </div>
          <div className="col-lg-4 info-container">
            <div>
              <h3>Social Media</h3>
            </div>
            <div>
              <Button>
                <img src={LinkedInIcon} alt="" className="social-media-icons" />
              </Button>
              <Button>
                <img src={FacebookIcon} alt="" className="social-media-icons" />
              </Button>
              <Button>
                <img
                  src={InstagramIcon}
                  alt=""
                  className="social-media-icons"
                />
              </Button>
              <Button>
                <img src={TwitterIcon} alt="" className="social-media-icons" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
