import { useEffect } from "react";
import Training from ".";
import LocalRecruitment from ".";
import Footer from "../Footer";
import Header from "../Header";
import "./index.scss";
function RecruitmentPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <LocalRecruitment />
      <Footer />
    </>
  );
}
export default RecruitmentPage;
