import React, { useEffect } from "react";
import CareerOppourtunity from ".";
import CareerOpportunity from ".";
import OurServices from ".";

import Footer from "../Footer";
import Header from "../Header";

function CareeerPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <CareerOpportunity />
      <Footer />
    </>
  );
}

export default CareeerPage;
