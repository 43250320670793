import "./index.scss";
import homebanner from "../../assets/images/Home/home-img.png";
import Header from "../Header";

import Footer from "../Footer";
import WelcomeScreen from "../Home";
import AboutUs from "../About-Us";
import OurServices from "../Our-Services";
import CareerOppourtunity from "../Career-Oppourtunty";

import LocalRecruitment from "../Flower-Warehouse";
import Training from "../Training";
import VisaInformation from "../Training";
import ContactUs from "../Contact-Us";

function Home() {
  return (
    <div className="container-fluid">
      <div className="row">
        <Header />
      </div>
      <div className="row">
        <WelcomeScreen />
      </div>
      {/* <div className="row">
        <AboutUs />
      </div>

      <div className="row">
        <OurServices />
      </div>
      <div className="row">
        <CareerOppourtunity />
      </div>
      <div className="row">
        <LocalRecruitment />
      </div>
      <div className="row">
        <VisaInformation />
      </div>
      <div className="row">
        <ContactUs />
      </div> */}

      <div className="row">
        <Footer />
      </div>
    </div>
  );
}
export default Home;
